import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ImageGrid } from "../components/ImageGrid"
import { Hero } from "../components/Hero"
import { TextColumns } from "../components/TextColumns"
import { StyledFlex } from "../components/StyledFlex"
import { Image } from "../components/Image"

const retailConceptImage = number => `retailConcept-${number}.jpg`

const RetailConceptPage = () => {
  return (
    <Layout isProjectPage>
      <SEO />

      <Hero>
        Retail Concept
        <Image src={retailConceptImage(7)} />
      </Hero>

      {[...Array(29)].map((image, index) => (
        <Image
          style={{ margin: "80px 0px" }}
          src={retailConceptImage(index + 1)}
        />
      ))}
    </Layout>
  )
}

export default RetailConceptPage
